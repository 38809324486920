.EventsArea {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.EventsArea-segmentAndToggleArea-expanded {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}

.EventsArea-segmentAndToggleArea-minimized {
  display: flex;
  justify-content: flex-end;
}

.EventsArea-segmentController-expanded {
  margin-top: 20px;
  margin-bottom: 10px;
}

.rotated {
  position: relative;
  left: var(--small-font-size);
  top: -0.3rem;

  transform-origin: left bottom;
  /* Safari */
  -webkit-transform: rotate(-45deg);

  /* Firefox */
  -moz-transform: rotate(-45deg);

  /* IE */
  -ms-transform: rotate(-45deg);

  /* Opera */
  -o-transform: rotate(-45deg);

  /* Internet Explorer */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}

.card {
  display: grid;
  grid-template-columns: 3.5rem auto 3rem 3rem repeat(7, 2rem) auto;
  /* grid-template-columns: 2fr repeat(10, 3.5rem) 10fr; */

 /* grid-column-gap: 10px; */
  /* grid-template-columns: var(--events-area-grid); */
  margin-top: var(--grid-header-height);
  width: 100%;
}

.r {
  border: var(--dotted-line);
  text-align: center;
  padding: 5px;
}

.r-left {
  border: var(--dotted-line);
  text-align: left;
  padding: 5px;
}

@media print {
  .card {
    display: grid;
    grid-auto-columns: min-content minmax(min-content, 2rem) repeat(9, minmax(min-content, 2rem)) minmax(5rem, min-content);
    grid-auto-rows: min-content;
  }
}
