.EventItem-timeline {
  display: grid;
  grid-template-columns: 60px auto 100px;

  margin-left: 5px;
  margin-right: 5px;

  align-items: center;
}

.EventItem-table {
  display: flex;
  flex-direction: row;
  /* grid-template-columns: auto auto 100px; */
  padding: 5px;
  /* align-items: stretch; */
  /* width: 100%; */
}

.EventItem-table-bkg-even {
  background-color: white;
}

.EventItem-table-bkg-odd {
  background-color: #f8f8f8;
}

.EventItem-icon-hiveEvent {
  width: 20px;
  height: 20px;
  padding: 10px;
  border-radius: 50%;
  border: 0.5px solid black;
}

.EventItem-icon-groupEvent {
  width: 20px;
  height: 20px;
  padding: 10px;
  border-radius: 20%;
  border: 0.5px solid black;
}

.EventItem-date-timeline {
  text-align: right;
  margin-left: 15px;
}

.EventItem-date-table {
  white-space: nowrap;
  text-align: right;
  margin-right: 15px;
}

.EventsItem-nameDescriptionArea-timeline {
  /* display: flex;
  flex-direction: column; */
}

.EventItem-eventName {
  width: 200px;
}

.EventItem-description {
  min-width: 200px;
  width: 100%;
}

.EventItem-imageWithLineArea {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.EventItem-threeColGrid-table {
  display: grid;
  grid-template-columns: auto 200px 1fr;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 5px;
}

.EventItem-threeColGrid-inspection {
  display: grid;
  grid-template-columns: 33% 33% 33%;
  align-items: center;
  justify-content: flex-start;
}
