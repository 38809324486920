.GroupHiveArrangementArea {
  width: auto;
}

.hiveArrangementSquare {
  height: 20px;
  width: 70px;

  margin: 6px;
  padding: 2px;

  font-size: 12px;
  text-align: center;

  border-radius: 6px;
  border-width: 1px;
}

.itemEmpty {
  background-color: #F5F5F5;
  color: lightGray;

  border-style: dotted;
  border-color: lightGray;
}

.itemEmptyInvisible {
  background-color: clear;
  margin: 7px; /*2px border + 6px margin*/
}

.itemOccupied {
  background-color: #D9D9D9;

  color: black;
  font-weight: 600;

  border-style:solid;
  border-radius: 5px;
  border-color: darkGray;
}

.itemCaption {
  background-color: clear;
  text-align: center;

  color: black;
  font-weight: 600;
  /* text-decoration: underline; */

  margin: 6px;
  padding: 2px;

  /* border-bottom-style: double;
  border-width: 1px;
  border-color: black; */

}

.GHAA-column {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    margin: 4px;
    border-radius: 6px;
    background-color: #F1F1F1;

    /* border-style: double;
    border-width: 1px;
    border-color: black; */
}

.GHAA-start {
    display: flex;
    flex-direction: row;
    flex-wrap:wrap;
    justify-content:flex-start;
    align-items: flex-start;



    /* width: 400px; */
    /* overflow:visible; */
}
