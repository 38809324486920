/* Mobile */
@media only screen and (max-width: 767px) {
  .App {
    /* display: flex;
    flex-direction: column; */
  }

  .mobileContentArea {
    padding: 20px;
  }

  .mobile {
  }

  .desktop {
    display: none;
  }
}

/* Desktop */
@media only screen and (min-width: 768px) {
  .App {
    /* display: flex;
    flex-direction: column; */

    /* Det fungerar bra att ha detta här: ger hela innehållet bakgrund, men vid
    för lite innehåll så sträcker det sig inte ändra ner. Problmet är inte här,
    utan att alla sub-komponenter måste täcka full höjd! */
    height: 100%;
    min-height: 100vh; /* LÖSNINGEN!!! */

/*    background-color: #fafafa;
    border-right: 1px solid lightgray;*/
    /*box-shadow: var(--light-box-shadow);*/

/* !!! */
/*    max-width: 1500px;
    min-width: 1000px;*/
  }

  .mobile {
    display: none;
  }

  .desktop {
    /* Setting width here will adjust the menu + page contents */
    /* width: 920px; */
  }
}

@media print {
  .App {
    background-color: white;
  }
}

.App-signedOut-infoArea {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin: 100px;
}

.App-signedOut {
  user-select: none;
}

/* The snackbar - position it at the bottom and in the middle of the screen */
#snackbar {
    visibility: hidden; /* Hidden by default. Visible on click */
    min-width: 200px; /* Set a default minimum width */
    margin-left: -100px; /* Divide value of min-width by 2 */
    background-color: black; /* Black background color */
    color: white; /* White text color */
    text-align: center; /* Centered text */
    border-radius: var(--normal-border-radius); /* Rounded borders */
    padding: 16px; /* Padding */
    position: fixed; /* Sit on top of the screen */
    z-index: 1; /* Add a z-index if needed */
    right: 50px; /* Center the snackbar */
    bottom: 30px; /* 30px from the bottom */
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
#snackbar.show {
    visibility: visible; /* Show the snackbar */
    /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
   However, delay the fade out process for 2.5 seconds */
   -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
   animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
    from {bottom: 0; opacity: 0;}
    to {bottom: 30px; opacity: 1;}
}

@keyframes fadein {
    from {bottom: 0; opacity: 0;}
    to {bottom: 30px; opacity: 1;}
}

@-webkit-keyframes fadeout {
    from {bottom: 30px; opacity: 1;}
    to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
    from {bottom: 30px; opacity: 1;}
    to {bottom: 0; opacity: 0;}
}

.toastButton {
  width: 100px;
  height: 25px;
  background-color: white;
  color: black;
  border-radius: var(--normal-border-radius);
}
