.HivesOverviewTableItem {
  width: 100%;
  padding-bottom: 30px;
}

.HivesOverviewTableItem-inner {
  display: flex;
  flex-direction: column;
  width: 100%;

  background-color: white;

  box-shadow: var(--normal-box-shadow);
  border-radius: var(--big-border-radius);
}

.HivesOverviewTableItem-inner:hover {
  cursor: alias;
}

.HivesOverviewTableItem-headerArea {
  display: flex;
  height: 40px;
  width: 100%;
  align-items: flex-end;
  padding-bottom: 7px;
}

.HivesOverviewTableItem-descriptionArea {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 0px;
}

.HivesOverviewTableItem-image {
  position: relative;
  top: 5px;

  width: 100%;

  border-bottom-right-radius: var(--normal-border-radius);
  border-bottom-left-radius: var(--normal-border-radius);
}

.HivesOverviewTableItem-badge {
  position: absolute;
  left: 5px;
  top: 12px;
}

.HivesOverviewTableItem-badge-container {
  position: relative;
}
