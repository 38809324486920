.GenericBarDiagram {
  margin: 30px;
  /* display: none; */
  /* background-color: red; */
  /* width: 100%; */
}

.GenericBarDiagram-labels {
  background-color: yellow;
}
