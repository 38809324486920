.TopHeaderMobile {
  padding-top: 20px;
  padding-bottom: 20px;

  height: 70px;
  width: 100%;

  background-image: url("../img/bkg-lightblue.jpg");
  border-bottom: 1px solid lightgray;
  box-shadow: var(--normal-box-shadow);

  user-select: none;
}

.TopHeader-logoArea {
  height: 70px;
  width: auto;
  padding-right: 20px;
}

.TopHeaderMobile-logo-img {
  height: 100%;
  width: auto;
}

.TopHeaderMobile-title {
  padding-left: 20px;
}
