.SegmentControllerItem {
  user-select: none;

  display: flex;
  flex-direction: row;

  background-color: white;
  border: 0.5px solid black;
  white-space: nowrap;

  border-radius: var(--small-border-radius);
  box-shadow: var(--normal-box-shadow);

  height: var(--segment-height);
}

.SegmentControllerItem-button {
  padding-left: 8px;
  padding-right: 6px;
  padding-top: 1px;
  padding-bottom: 3px;

  color: black;
}

.SegmentControllerItem-button-selected {
  border-radius: var(--small-border-radius);

  padding-left: 8px;
  padding-right: 8px;
  padding-top: 1px;
  padding-bottom: 3px;

  background-color: black;
  color: white;
}

.SegmentControllerItem-buttonSeparator {
  width: 0px;
}
