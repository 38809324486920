.EventTimeLineVerticalLine {
  border-left: 1px solid black;
  height: 100%;
  min-height: 10px;
  margin-left: 20px;
}

.EventTimeLineVerticalLine-invisible {
  /* border-left: 1px solid black; */
  height: 100%;
  min-height: 10px;
  margin-left: 20px;
}
