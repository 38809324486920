.SideBySideArea {
  display: grid;
  grid-template-columns: 200px auto;
}

.SideBySideArea-MenuArea {
  user-select: none;

  height: 100%;
  min-height: 100%;

  text-align: left;
  color: black;
  padding: 10px;
  margin-top: 20px;
}

.SideBySideArea-menuItem {
  height: 2rem;
  margin-left: 1rem;
  padding-bottom: 1em;
}

.SideBySideArea-menuItem:hover {
  text-decoration: underline;
}

.SideBySideArea-PageArea {
  text-align: left;
  color: black;
  padding: 20px;
  height: 100%;
  min-height: 100%;

  max-width: 1200px;
  min-width: 1000px;
}

@media print {
  .SideBySideArea-PageArea {
    width: var(--print-width-a4);
  }
}
