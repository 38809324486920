.HiveImageItem {
  /* padding: 5px; */
}

.HiveImageItem-image {
  border-radius: var(--normal-border-radius);
  box-shadow: var(--light-box-shadow);
  height: 180px;
  width: 180px;
}
