.navbar {
  overflow: hidden;
  user-select: none;
  /* border-bottom: 1px solid lightgray; */

  margin-bottom: 30px;
  height: 100%;
}

/* .navbar a {
  float: left;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
} */

.dropdown {
  float: left;
  overflow: hidden;
}

.dropdown .dropbtn {
  color: black;
  /* background-color: #CECFCE; */
  background-color: white;
  font-size: var(--small-font-size);
  font-weight: normal;
  border: 1px solid black;
  outline: none;
  padding: 7px 9px;
  /* margin-left: 5px; */
  margin-right: 5px;
  /* height: 1.4em; */
  box-shadow: var(--normal-box-shadow);

  border-radius: var(--normal-border-radius);
}

.navbar a:hover, .dropdown:hover .dropbtn {
  color: white;
  background-color: black;
}

.dropdown-content {
  color: white;
  background-color: black;
  display: none;
  position: absolute;
  min-width: 160px;
  box-shadow: var(--normal-box-shadow);
  z-index: 1;
  margin-left: 5px;
}

.dropdown-content-item {
  float: none;
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;

  font-size: var(--small-font-size);
  font-weight: normal;
}

.dropdown-content-item:hover {
  color: black;
  /* background-color: #C4CCCD; */
  background-color: #CECFCE;
  /* background-color: #9AB6C1; */
}

.dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

/* .dropdown-content a:hover {
  color: red;
} */

.dropdown:hover .dropdown-content {
  display: block;
}

@media print {
  .navbar {
    display: none;
  }
}
