.GroupsItem {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 10px;
}

.GroupsItem-header {
  padding-bottom: 10px;
}

.GroupsItem-content {
}
