:root {
  /* --button-height: 1.4rem; */
  --segment-height: 1.7rem;

  --huge-font-size: 2.3rem;
  --big-font-size: 1.4rem;
  --normal-font-size: 1.0rem;
  --small-font-size: 0.8rem;
  --tiny-font-size: 0.6rem;

  --small-border-radius: 3px;
  --normal-border-radius: 5px;
  --big-border-radius: 5px;

  --grid-column-width: 3.5rem;
  --grid-column-width-small: 2rem;
  --grid-header-height: 7rem;

  /* --events-area-grid: 4% 15% 4% 4% repeat(7, 3%) auto; */
  /* --events-area-grid: var(--grid-column-width) 15% 4% 4% repeat(7, 3%) auto; */
  /* --events-area-grid: var(--grid-column-width) auto var(--grid-column-width) var(--grid-column-width) repeat(7, var(--grid-column-width-small)) auto; */

  --spacing-small: 15px;

  --xyOffset: 2px;
  --normal-box-shadow: var(--xyOffset) var(--xyOffset) 6px lightgray;
  --light-box-shadow: var(--xyOffset) var(--xyOffset) 10px lightgray;

  --dotted-line: 0.2px dotted lightgray;
  --solid-line: 0.2px solid black;

  --print-width-a4: 21cm;
  --print-height-a4: 29.7cm;
}

html, body {
  height: 100%; /*ska vara 100% och inte auto*/
  min-height: 100%;
  margin: 0px;
  padding: 0px;
  background-color: #fafafa;
}

body {
  font-size: var(--normal-font-size);
  font-family: 'Open Sans', sans-serif;
  color: black;
  text-align: left;
}

#map {
  width: 100%;
  height: 500px;
}

.info-box {
  background-color: lightgray;
  color: black;
  border-radius: var(--normal-border-radius);
  text-align: center;
  width: 100%;
  height: 100%;
}

.info-box-500 {
  background-color: lightgray;
  color: white;
  border-radius: var(--normal-border-radius);
  text-align: center;
  width: 100%;
  height: 500px;
}

.b {
  font-weight: 600;
}

a {
  text-decoration: none;
  color: black;
}

.lowercase {
  text-transform: lowercase;
  display: inline;
}

.smallcaps {
  text-transform: uppercase;
  display: inline;
}

.smallcaps-italic {
  /* font-variant: small-caps; */
  text-transform: uppercase;
  font-style: italic;
  display: inline;
}

.center-text {
  text-align: center;
  width: 100%;
}

.left-text {
  text-align: left;
  width: 100%;
}

.right-text {
  text-align: right;
  width: 100%;
}

.noWrap {
  white-space: nowrap;
}

.underlineRow {
  white-space: nowrap;
  padding-bottom: 7px;
  border-bottom: 0.5px solid lightgray;
  width: 100%;
  min-width: 100%;
}

.text-almost-invisible {
  color: gray;
}

.space-above-tiny { margin-top: 5px; }
.space-above-small { margin-top: var(--spacing-small); }
.space-above-medium { margin-top: 50px; }
.space-above-big { margin-top: 100px; }

.space-under-tiny { margin-bottom: 5px; }
.space-under-small { margin-bottom: var(--spacing-small); }
.space-under-medium { margin-bottom: 50px; }
.space-under-big { margin-bottom: 100px; }
/*
.full-height {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  height: 100%;
  min-height: 100%;
} */

.center-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.box-item-w60 {
  width: 60%;
  min-width: 60%;
}

.box-item-w70 {
  width: 70%;
  min-width: 70%;
}

.box-item-w90 {
  width: 90%;
  min-width: 90%;
}

.box-item-w100 {
  width: 100%;
  min-width: 100%;
}

.caption-small {
  display: inline;
  font-size: var(--small-font-size);
  font-weight: 600;
  margin-right: 5px;
}

.caption-normal {
  display: inline;
  font-size: var(--normal-font-size);
  margin-right: 5px;
}

.caption-big {
  display: inline;
  font-size: var(--big-font-size);
  font-weight: 500;
}

.caption-huge {
  display: inline;
  font-size: var(--huge-font-size);
  font-weight: 300;
}

.p-tiny {
  font-size: var(--tiny-font-size);
  font-weight: normal;
}

.p-small {
  font-size: var(--small-font-size);
  font-weight: normal;
}

.p {
  font-size: var(--normal-font-size);
  font-weight: normal;
}


/* -------------------------------------------------------------------------- */
/* GENERIC */
/* -------------------------------------------------------------------------- */

.idBadge {
  display: inline;
  color: black;
  background-color: white;
  border-radius: var(--small-border-radius);
  padding: 0px 0px 1px 5px;
  margin-right: 5px;
  border: 0.5px solid black;
}


.buttonRow {
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;
  width: 100%;
}

.buttonRowLR {
  display: grid;
  grid-template-columns: auto auto;
  /* align-items: center; */
  /* align-content: space-between; */
  justify-content: space-between;
  width: 100%;
  min-width: 100%;
}

.buttonRowLCR-fixed {
  display: grid;
  grid-template-columns: 33% auto 33%;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-width: 100%;
}

.buttonRowLCR-80 {
  display: grid;
  grid-template-columns: 45% auto 45%;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-width: 100%;
}


.align-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  width: 100%;
  min-width: 100%;
}

.align-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 100%;
  min-width: 100%;
}

.buttonRowLCR {
  display: grid;
  grid-template-columns: auto auto auto;
  /* align-items: center; */
  /* align-content: space-between; */
  justify-content: space-between;
  width: 100%;
  min-width: 100%;
}

.generic-button-small {
  width: 2rem;
  user-select: none;
  font-size: var(--small-font-size);
  background-color: black;
  /* border: 0.5px solid white; */
  color: white;
  /* text-transform: uppercase; */
  /* font-weight: 600; */
  padding-top: 0.2rem;
  padding-bottom: 0.3rem;
  padding-right: 0.2rem;
  padding-left: 0.2rem;
  border-radius: var(--small-border-radius);
  box-shadow: var(--normal-box-shadow);
  text-align: center;
  white-space: nowrap;
  /* height: var(--button-height); */
}

.generic-button {
  user-select: none;
  background-color: white;
  border: 0.5px solid black;
  padding-top: 2px;
  padding-bottom: 4px;
  padding-right: 3px;
  padding-left: 8px;
  border-radius: var(--small-border-radius);
  box-shadow: var(--normal-box-shadow);
  text-align: center;
  white-space: nowrap;
  /* height: var(--button-height); */
}

.generic-button:hover {
  /* box-shadow: 0px 0px 15px lightgray; */
  color: white;
  background-color: black;
}

@media only screen and (min-width: 767px) {
  /* Only show on print */
  .only-print {
    display: none;
  }
}

@media print {

  :root {
    /* --button-height: 0.7rem; */
    --segment-height: 0.85rem;

    --huge-font-size: 1.3rem;
    --big-font-size: 0.9rem;
    --normal-font-size: 0.5rem;
    --small-font-size: 0.4rem;
    --tiny-font-size: 0.3rem;

    --grid-column-width: 2rem;
    --grid-column-width-small: 1.5rem;
    --grid-header-height: 5rem;

    /* --events-area-grid: 4% 15% 4% 4% repeat(7, 3%) auto; */
    /* --events-area-grid: var(--grid-column-width) auto var(--grid-column-width) var(--grid-column-width) repeat(7, var(--grid-column-width-small)) auto; */

    --normal-box-shadow: none;
    --light-box-shadow: none;
  }

  /* Don't print this div */
  .no-print {
    display: none;
  }

  /* Only show on print */
  .only-print {
    /* display: inherit; */
  }

  /* Don't print buttons */
  .generic-button {
    display: none;
  }

  .html, body {
    background-color: white;
    border-right: 0px;

    margin: 0px;
    padding: 0px;

    width: var(--print-width-a4);
    /* min-height: var(--print-height-a4); */
  }

  .print-break-inside-never {
    -webkit-column-break-inside: avoid; /* Chrome, Safari, Opera */
              page-break-inside: avoid; /* Firefox */
                   break-inside: avoid;  /* IE 10+ */
  }

  .print-break-inside-always {
    -webkit-column-break-inside: always; /* Chrome, Safari, Opera */
              page-break-inside: always; /* Firefox */
                   break-inside: always; /* IE 10+ */
  }

  .print-break-before {
    break-before: always;
    page-break-before: always;
  }

  .print-break-after {
    break-after: always;
    page-break-after: always;
  }

  #map {
    width: var(--print-width-a4);
  }
}

.imageShadowBox {
  border-radius: var(--normal-border-radius);
  box-shadow: var(--light-box-shadow);
}
