.TopHeader {
  display: grid;
  grid-template-columns: 40% 20% 40%;

  align-items: center;
  align-content: space-between;

  padding-top: 20px;
  padding-bottom: 20px;

  height: 100px;
  width: 100%;

  background-image: url("../img/bkg-lightblue.jpg");
  background-size: cover;
  background-repeat: no-repeat;

  border-bottom: 1px solid lightgray;
  box-shadow: var(--normal-box-shadow);

  user-select: none;
}

.TopHeader-logo {
  text-align: center;
  align-self: center;
}

.TopHeader-logo-img {
  height: 100px;
  width: auto;
}

.TopHeader-title {
  padding-left: 20px;
}

.TopHeader-userIcon {
  text-align: right;
  align-self: flex-start;
}

.TopHeader-userIcon-tab {
  float: right;
  align-self: center;
  background-color: #fefefe;
  width: 38px;
  height: 35px;

  padding-top: 5px;
  padding-right: 5px;

  border-top-left-radius: var(--normal-border-radius);
  border-bottom-left-radius: var(--normal-border-radius);

  /* border: 1px solid lightgray; */
  box-shadow: var(--normal-box-shadow);
}

.TopHeader-userIcon-tab:hover {
  box-shadow: var(--light-box-shadow);
}

.TopHeader-userIcon-img {
  height: 30px;
}

.TopHeader-userIcon {
}

.TopHeader-userPopup {
  display: none;
}

.signOutArea {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  /* background-color: red; */
  width: 100%;
}

/* ----------------- */

/* The Overlay (background) */
.overlay {
  color: white;

  /* Height & width depends on how you want to reveal the overlay (see JS below) */
  height: 100%;
  width: 0;
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  background-color: rgb(0,0,0); /* Black fallback color */
  background-color: rgba(0,0,0, 0.9); /* Black w/opacity */
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.5s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
}

/* Position the content inside the overlay */
.overlay-content {
  position: relative;
  top: 25%; /* 25% from the top */
  width: 100%; /* 100% width */
  text-align: center; /* Centered text/links */
  margin-top: 30px; /* 30px top margin to avoid conflict with the close button on smaller screens */
}

/* The navigation links inside the overlay */
.overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: var(--big-font-size);
  color: #818181;
  display: block; /* Display block instead of inline */
  transition: 0.3s; /* Transition effects on hover (color) */
}

/* When you mouse over the navigation links, change their color */
.overlay a:hover, .overlay a:focus {
  color: #f1f1f1;
}

/* Position the close button (top right corner) */
.overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: var(--huge-font-size);
  color: white;
}

/* When the height of the screen is less than 450 pixels, change the font-size of the links and position the close button again, so they don't overlap */
/* @media screen and (max-height: 450px) {
  .overlay a {
    font-size: 20px
  }
  .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
} */

@media print {
  .TopHeader {
    background-image: none;
  }
}
