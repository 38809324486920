.UserGuideSubPage {
  display: flex;
  flex-direction: column;

  align-items: center;

  max-width: 600px;
}

.UserGuideSubPage-2cell {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.UserGuideSubPage-2cell-img {
  width: 40px;
  height: 100%;
}

.UserGuideSubPage-2cell-imgcell {
  min-width: 50px;
  height: 100%;
  margin-right: 20px;
}

.UserGuideSubPage-main {
  display: flex;
  flex-direction: column;
  margin-top: 7px;
  width: 100%;
}

.UserGuideSubPage-subTopics {
  display: flex;
  flex-direction: column;
}
