.ToggleDetailsItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  user-select: none;
  cursor: alias;
}

.ToggleDetailsItem-icon {
  width: 12px;
  height: 12px;
  margin-top: 2px;
  margin-left: 5px;
}
