.NewsletterPage {
}

.dark {
  padding: 5px;
  border-radius: var(--normal-border-radius);
  color: white;
  background-color: #424242;
  margin-bottom: 10px;
}

.w750 {
  width: 750px;
}

.newsletter-bkg {
  background-color: #f1f2f3;
  padding: 10px;
  border-radius: 5px;
}
