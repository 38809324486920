.GroupDetail {
  --spacing-small: 15px;
}

  /* Tooltip container */
.tooltip {
  position: relative;
  /* right: -350px; */
  display: inline-block;

  /* If you want dots under the hoverable text */
  /* border-bottom: 1px dotted black; */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 250px;
  background-color: black;
  color: #ebebeb;
  text-align: left;
  padding: 5px;
  border-radius: var(--small-border-radius);

  /* Position the tooltip text - see examples below! */
  position: absolute;
  left: -250px;

  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}


.group-event-top {
  user-select: none;
  padding: var(--spacing-small);
  background-color: #ebebeb;
  border-radius: var(--small-border-radius);
  /* border-color: black; */
  /* border-width: 1px; */
  box-shadow: var(--normal-box-shadow);

  display:flex;
  flex-flow: row;
}

.group-event-bottom {
  margin-left: 15px;
  margin-right: 15px;
  padding: var(--spacing-small);
  /* background-color: #c9caca; */
  border-radius: var(--big-border-radius);

  box-shadow: var(--normal-box-shadow);

  border-right: var(--dotted-line);
  border-left: var(--dotted-line);
  border-bottom: var(--dotted-line);
  /* border-color: black;
  border-width: 1px; */
  /* border: var(--dotted-line); */
}

.GroupDetail-layout {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.GroupDetail-groupthumbnailAndDetailsArea {
  width: 200px;
  margin-right: 40px;
}

.GroupDetail-hivesArea {
  padding-top: var(--spacing-small);
  flex-direction: row;
  width: 100%;
  max-width: 100%;
}

.GroupDetail-sharingStatusItem {
  margin-bottom: var(--spacing-small);
}

.GroupDetail-descriptionItem {
  margin-bottom: var(--spacing-small);
}

.GroupDetail-descriptionArea {
  color: black;
}

.GroupDetail-titleAndShortName {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  border-bottom: 0.5px solid lightgray;
}

.GroupDetail-thumbnail {
  padding-top: var(--spacing-small);
  padding-bottom: var(--spacing-small);
}

.GroupDetail-titleAndLinkArea {
  display: flex;
  justify-content: space-between;
  padding-right: 5px;
}

.GroupDetail-linkIcon {
  flex-direction: row;
}

.GroupDetail-twoColGrid {
  display: grid;
  grid-template-columns: 80px auto;
}

.GroupDetail-twoCellGrid-firstCol {
  margin-right: 10px;
}
