.LoadingIndicator {
  height: 100%;
  width: 100%;

  padding: 50px;
  margin-top: 100px;

  text-align: center;
}

.LoadingIndicator-logo {
  animation: LoadingIndicator-spin infinite 4s linear;
}

@keyframes LoadingIndicator-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
