.HivesOverviewTable {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 100%;
}

.HivesOverviewTable-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.HivesOverviewTable-item {
  margin: 15px;
  width: 100%;
  min-width: 120px;
  max-width: 160px;
}
