.StatisticsPage {
  width: 100%;
  max-width: 100%;
}

.StatisticsPage-content {
  width: 100%;
}

.StatisticsPage-sankey {
  width: 100%;
  height: 400px;
}

.dHide {
  display:none;
}

.dShow {
  display: block;
}

.StatisticsPage-hiveDeath-sideBySideArea {
  display: grid;
  /* grid-auto-flow: column; */
  grid-template-columns: 4fr 1fr 2fr;
  /* grid-auto-rows: min-content; */
  /* grid-template-rows: auto auto; */
  /* grid-template-rows: 400px; */
  /* justify-content: space-between; */
  /* width: 100%;
  min-width: 100%; */
/* background-color: yellow; */
  /* height: 400px; */
  /* margin: 50px; */
}

.statisticTypeButton-area {
  display: grid;
  grid-column: auto;
  /* en rad med squares*/
  width: 100%;
}

.statisticTypeButton {
  width: 100px;
  height: 100px;
  border-radius: var(--normal-border-radius);
  border: var(--solid-line);

  /* height 1fr; */
  /* background-color: red; */
}
