.HivesInGroupDetail {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 100%;
}

.HivesInGroupDetail-header-missing-data {
  text-align: center;
  height: 100%;
  padding: 50px;
}

.HivesInGroupDetail-twoColGrid {
  display: grid;
  grid-template-columns: 80px auto;
}

.HivesInGroupDetail-twoColGrid-year {
  display: grid;
  grid-template-columns: 15px auto;
  align-items: center;
  justify-content: flex-start;
}

.HivesInGroupDetail-twoColGrid-auto {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: flex-start;
}
