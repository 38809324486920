.LinkIcon {
  height: 20px;
  width: 20px;
  background-color: transparent;
}

.LinkIcon-icon {
  height: 100%;
  width: 100%;
}

@media print {
  .LinkIcon {
    display: none;
  }
}
