.UnderlinedIdAndCaptionSeparator {
  white-space: nowrap;
  width: 100%;
  min-width: 100%;

  /* background-color: red; */
  /* border-left: 1px solid black;
  height: 100%;
  min-height: 10px;
  margin-left: 20px; */
}
