.HiveItem {
  display: flex;
  flex-direction: column;

  /* background-color: brown; */
  background-color: white;

  width: 100%;
  max-width: 100%;

  border-radius: var(--big-border-radius);
  box-shadow: var(--normal-box-shadow);

  /* margin-bottom: 40px; */
}

.pp {
  padding: 15px;
}

.HiveItem-infoArea {
  /* margin: 15px; */
  /* display: grid; */
  /* grid-template-columns: repeat(5, 1fr); */
  /* flex-direction: column; */
  width: 100%;
  max-width: 100%;
}

.HiveItem-thumbnailArea {
  padding-right: 15px;
}

.r2 {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.HiveItem-titleDescriptionSeparator {
  height: 0px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.HiveItem-detailsAndEventsArea {
  display: flex;
  flex-direction: column;
  /* align-items:center; */
  width: 100%;
}

/* .HiveItem-titleAndLinkIconArea {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 100%;
  justify-content: space-between;
  align-items: center;
} */


.HiveItem-detailsArea {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.HiveItem-detailsAndEventsColumn {
  display: flex;
  flex-direction: column;
}

.HiveItem-description {
  min-width: 30%;
  width: 30%;
}

.HiveItem-details {
  border-left: var(--dotted-line);
  padding-left: 10px;
  min-width: 35%;
  width: 35%;
}

.HiveItem-details2 {
  border-left: var(--dotted-line);
  padding-left: 10px;
  min-width: 35%;
  width: 35%;
}


.greenStyle {
  border-radius: var(--normal-border-radius);
  width: 10px;
  height: 10px;
  border-radius: var(--small-border-radius);
  border: 0.5px solid black;
  background-color: green;
}

.redStyle {
  border-radius: var(--normal-border-radius);
  width: 10px;
  height: 10px;
  border-radius: var(--small-border-radius);
  border: 0.5px solid black;
  background-color: red;
}

.blueStyle {
  border-radius: var(--normal-border-radius);
  width: 10px;
  height: 10px;
  border-radius: var(--small-border-radius);
  border: 0.5px solid black;
  background-color: blue;
}

.yellowStyle {
  border-radius: var(--normal-border-radius);
  width: 10px;
  height: 10px;
  border-radius: var(--small-border-radius);
  border: 0.5px solid black;
  background-color: yellow;
}

.whiteStyle {
  border-radius: var(--normal-border-radius);
  width: 10px;
  height: 10px;
  border-radius: var(--small-border-radius);
  border: 0.5px solid black;
  background-color: white;
}

.arrangementBadge {
  border-radius: var(--small-border-radius);
  background-color: black;
  color: white;
  text-align: center;
  font-size: 13px;
  padding: 5px;
  width: auto;
  margin-right: 10px;
}
